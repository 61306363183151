.container {
  background: rgba(255, 255, 255, 0.92);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  height: 80%;
  width: 80%;
  max-width: 1200px;
  border-radius: 10px;
  animation: 2s slidein;
  box-shadow: 0px 0px 22px -1px rgba(252, 255, 144, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: scale(0.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
